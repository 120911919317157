import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import Gallery from 'react-photo-gallery';
import { Wrapper } from './styles';

export const Section = () => {
  const { t } = useTranslation();

  return (
    <Wrapper id="galerija">
      <Gallery photos={t('section.gallery.items', { returnObjects: true })} direction={'row'} />
    </Wrapper>
  );
};
