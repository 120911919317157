import React from 'react';

import { Content, SlideContent, Subtitle, Title, ImageWrapper } from './styles';

import { StaticImage } from 'gatsby-plugin-image';
import { Col, Container, Row } from 'react-bootstrap';
import { GoTriangleDown } from 'react-icons/go';
import { Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

export interface SliderItem {
  title: string;
  subtitle: string;
  content: string;
  image: string;
}

export const Slider = ({ items }: { items: SliderItem[] }) => {
  return (
    <Swiper
      spaceBetween={0}
      modules={[Autoplay, EffectFade]}
      effect="fade"
      speed={1000}
      loop
      autoplay={{ delay: 5000 }}
      slidesPerView={1}
    >
      {/* NOTE: not foreachable because of StaticImage */}
      {/* FIRST */}
      <SwiperSlide key={items[0].title}>
        <ImageWrapper>
          <StaticImage
            src="../../../../../static/images/l&l_motors_image_29.webp"
            alt={items[0].title}
            layout="fullWidth"
            placeholder="dominantColor"
          />
          <StaticImage
            src="../../../../../static/images/l&l_motors_image_30.webp"
            alt={items[0].title}
            layout="fullWidth"
            placeholder="dominantColor"
          />
        </ImageWrapper>

        <Content>
          <Container>
            <Row>
              <Col lg={7}>
                <div className="d-flex flex-column align-items-start">
                  <Subtitle>
                    {items[0].subtitle}
                    <GoTriangleDown />
                  </Subtitle>

                  <Title>{items[0].title}</Title>
                  <SlideContent>{items[0].content}</SlideContent>
                </div>
              </Col>
            </Row>
          </Container>
        </Content>
      </SwiperSlide>

      {/* SECOND */}
      <SwiperSlide key={items[1].title}>
        <ImageWrapper>
          <StaticImage
            src="../../../../../static/images/l&l_motors_image_28.webp"
            alt={items[1].title}
            layout="fullWidth"
            placeholder="dominantColor"
          />
          <StaticImage
            src="../../../../../static/images/l&l_motors_image_38.webp"
            alt={items[1].title}
            layout="fullWidth"
            placeholder="dominantColor"
          />
        </ImageWrapper>

        <Content>
          <Container>
            <Row>
              <Col lg={8}>
                <div className="d-flex flex-column align-items-start">
                  <Subtitle>
                    {items[1].subtitle}
                    <GoTriangleDown />
                  </Subtitle>

                  <Title>{items[1].title}</Title>
                  <SlideContent>{items[1].content}</SlideContent>
                </div>
              </Col>
            </Row>
          </Container>
        </Content>
      </SwiperSlide>

      {/* THIRD */}
      <SwiperSlide key={items[2].title}>
        <ImageWrapper>
          <StaticImage
            src="../../../../../static/images/l&l_motors_image_24.webp"
            alt={items[2].title}
            layout="fullWidth"
            placeholder="dominantColor"
          />
          <StaticImage
            src="../../../../../static/images/l&l_motors_image_35.webp"
            alt={items[2].title}
            layout="fullWidth"
            placeholder="dominantColor"
          />
        </ImageWrapper>

        <Content>
          <Container>
            <Row>
              <Col lg={5}>
                <div className="d-flex flex-column align-items-start">
                  <Subtitle>
                    {items[2].subtitle}
                    <GoTriangleDown />
                  </Subtitle>

                  <Title>{items[2].title}</Title>
                  <SlideContent>{items[2].content}</SlideContent>
                </div>
              </Col>
            </Row>
          </Container>
        </Content>
      </SwiperSlide>

      {/* FOURTH */}
      <SwiperSlide key={items[3].title}>
        <ImageWrapper>
          <StaticImage
            src="../../../../../static/images/l&l_motors_image_33.webp"
            alt={items[3].title}
            layout="fullWidth"
            placeholder="dominantColor"
          />
          <StaticImage
            src="../../../../../static/images/l&l_motors_image_34.webp"
            alt={items[3].title}
            layout="fullWidth"
            placeholder="dominantColor"
          />
        </ImageWrapper>

        <Content>
          <Container>
            <Row>
              <Col lg={5}>
                <div className="d-flex flex-column align-items-start">
                  <Subtitle>
                    {items[3].subtitle}
                    <GoTriangleDown />
                  </Subtitle>

                  <Title>{items[3].title}</Title>
                  <SlideContent>{items[3].content}</SlideContent>
                </div>
              </Col>
            </Row>
          </Container>
        </Content>
      </SwiperSlide>
    </Swiper>
  );
};
